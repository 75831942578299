import VueRouter from 'vue-router'

import Home from './Components/Home.vue'
import Terms from './Components/Terms.vue'
import Privacy from './Components/Privacy.vue'
import Support from './Components/Support.vue'

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Home
        },
        {
            path: "/terms",
            component: Terms,
            name: "terms"
        },
        {
            path: "/privacy",
            component: Privacy,
            name: "privacy"
        },
        {
            path: "/support",
            component: Support,
            name: "support"
        }
    ]
})

export default router