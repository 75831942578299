<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100%;
  background-color:#8a2be2;
}

#logo {
  color: white;
  text-decoration: none;
}

#navigation-bar {
  color: white;
  font-size: 45px;
  font-weight: bold;
  width: 100%;
  height: 80px;
}

#content-body {
  min-height: calc(100vh - 80px);
  color: white;
  text-align: center;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

#appPreviewImg {
  object-fit: contain;
  max-height: calc(100vh - 100px);
}

#footer {
  width: 100%;
  /* height: 250px; */
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
}

#footerStack {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
}

#termsStack {
  display: flex;
}

#copyright {
  font-size: 16px;
  padding-top: 15px;
  text-align: center;
}

#appStoreBtn {
  object-fit: contain;
  padding-top: 15px;
  max-width: 250px;
}

#bodyTitle {
  font-weight: bold;
}

#bodySubtitle {
  padding-top: 15px;
}

.tLink {
  text-decoration: underline;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  #navigation-bar {
    text-align: center;
  }

  #content-body {
    flex-direction: column;
  }

  #termsStack {
    flex-direction: column;
    align-items: center;
  }

  #appPreviewImg {
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 20px);
  }

  #bodyTitle {
    font-size: 24px;
  }

  #bodySubtitle {
    font-size: 20px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #navigation-bar {
    text-align: center;
  }
  #content-body {
    flex-direction: column;
  }
  #termsStack {
    flex-direction: column;
    align-items: center;
  }
  #textStack {
    padding-top: 20%;
  }

  #appPreviewImg {
    max-height: calc(100vh - 100px);
    max-width: calc(100vh - 10%);
  }

  #bodyTitle {
    font-size: 24px;
  }

  #bodySubtitle {
    font-size: 20px;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #navigation-bar {
    text-align: left;
    padding-top: 12px;
    padding-left: 15%;
  }
  #termsStack {
    flex-direction: row;
  }
  #textStack {
    padding-right: 10%;
  }
  #privacy {
    padding-left: 10px;
  }
  #billing {
    padding-left: 10px;
  }

  #appPreviewImg {
    max-height: calc(100vh - 100px);
  }

  #bodyTitle {
    font-size: 36px;
  }

  #bodySubtitle {
    font-size: 28px;
  }
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
