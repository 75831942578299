<template>
  <div id="support">
      <div id="box"> 
          <h2>Support email: admin@monkeyrun.fun</h2>
      </div>
  </div>
</template>

<script>
export default {
  name: "support",
  methods: {}
};
</script>

<style>
#box {
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    margin-top: 10%;
}
</style>